import React from 'react';
import { Provider } from 'react-redux';
import { AuthKitProvider } from '@workos-inc/authkit-react';
import store from './store';
import CustomRouter from './router';
import config from './config';

function App() {
  return (
    <AuthKitProvider
      clientId={config.get(config.vars.workOs.clientId) || ''}
      redirectUri={config.get(config.vars.workOs.redirectUri) || ''}
    >
      <Provider store={store}>
        <CustomRouter />
      </Provider>
    </AuthKitProvider>
  );
}

export default App;
