const variables = {
  workOs: {
    clientId: "WORKOS_CLIENT_ID",
    redirectUri: "WORKOS_REDIRECT_URI"
  }
};

const getConfigVariable = (name: string): any => {
  if (process.env.NODE_ENV === "production") {
    // @ts-ignore
    if (window.secureSyncVars) {
      // @ts-ignore
      return window.secureSyncVars[name];
    }
    return null;
  }

  return process.env[`REACT_APP_${name}`];
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  vars: variables,
  get: getConfigVariable
};
