import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UsersTable from '../components/UsersTable';
import mockData from '../store/mocks/users-mocks.json';

export const UserManagement: React.FC = () => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState('Active');

  const filteredData = mockData.filter((user) => {
    if (selectedTab === 'WP Users') {
      return user.role === 'WP User';
    }
    return user.status === selectedTab;
  });

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold">{t('admin.userManagement.title')}</h1>

      <div className="flex space-x-4 border-b mt-4 mb-6">
        {['Active', 'Inactive', 'Unverified', 'Pending', 'WP Users'].map((status) => (
          <button
            key={status}
            onClick={() => setSelectedTab(status)}
            className={`pb-2 ${selectedTab === status ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'
              }`}
          >
            {status}
          </button>
        ))}
      </div>

      <UsersTable data={filteredData} />
    </div>
  );
};
