import React, { useEffect, useRef, useState } from 'react';
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

type Size = 'S' | 'M' | 'L';

type ChartProps = {
  title: string;
  labels: string[];
  datasetLabel: string;
  data: number[];
  backgroundColor?: string[];
  size?: Size;
};

const sizePresets: Record<Size, { width: string; height: string }> = {
  S: { width: '100%', height: '250px' },
  M: { width: '100%', height: '300px' },
  L: { width: '100%', height: '350px' },
};

export const ChartComponent: React.FC<ChartProps> = ({
  title,
  labels,
  datasetLabel,
  data,
  backgroundColor,
  size = 'M',
}) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const [currentSize, setCurrentSize] = useState<Size>(size);
  const theme = localStorage.getItem('theme');

  useEffect(() => {
    const updateSizeBasedOnScreen = () => {
      if (window.innerWidth < 1024) {
        setCurrentSize('S');
        setCurrentSize(size);
      }
    };

    updateSizeBasedOnScreen();
    window.addEventListener('resize', updateSizeBasedOnScreen);

    return () => {
      window.removeEventListener('resize', updateSizeBasedOnScreen);
    };
  }, [size]);

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    if (chartRef.current) {
      chartInstanceRef.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: datasetLabel,
              data,
              backgroundColor: backgroundColor || ['#f87171', '#fbbf24', '#34d399', '#60a5fa', '#a78bfa', '#f472b6'],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
              labels: {
                color: theme === 'light' ? '#374151' : '#fff',
                font: {
                  weight: 'bold',
                },
              },
            },
            title: {
              display: true,
              text: title,
              color: theme === 'light' ? '#1f2937' : '#fff',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              ticks: {
                color: '#6b7280',
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                color: '#6b7280',
              },
            },
          },
        },
      });
    }
  }, [title, labels, datasetLabel, data, backgroundColor, currentSize]);

  const { width, height } = sizePresets[currentSize];

  return (
    <div className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow-md text-gray-900 dark:text-gray-100" style={{ width, height }}>
      <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};
