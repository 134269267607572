import { FC, useState } from 'react';
import {
  MenuDropdown,
  MenuDropdownContent,
  MenuDropdownDivider,
  MenuDropdownItem,
  MenuDropdownTrigger,
} from './MenuDropdown';
import { Avatar } from './Avatar';
import { Button } from './Button';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import cs from 'classnames';
import { useAuth } from '@workos-inc/authkit-react';

export const UserSettingsButton: FC = () => {
  const { user, signOut } = useAuth();
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => setOpen((prev) => !prev);

  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
  const profilePicture = user?.profilePictureUrl || undefined;

  return (
    <MenuDropdown open={open} onClose={toggleDropdown}>
      <MenuDropdownTrigger>
        <Button
          onClick={toggleDropdown}
          leftIcon={<Avatar name={fullName} src={profilePicture} />}
          rightIcon={
            <ChevronRightIcon
              className={cs('h-5 w-5 transform transition-transform duration-200', {
                'rotate-90': open,
              })}
            />
          }
          aria-label="Open user settings"
        >
          {user?.firstName || 'User'}
        </Button>
      </MenuDropdownTrigger>

      <MenuDropdownContent
        className="mt-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg p-4"
        align="end"
        sideOffset={4}
      >
        <MenuDropdownItem className="hover:bg-gray-100 dark:hover:bg-gray-800 hover:border-none focus:outline-none">
          <div className="flex items-center space-x-3">
            <Avatar name={fullName} src={profilePicture} size="M" />
            <div>
              <div className="text-gray-900 dark:text-gray-100 font-bold">
                {fullName || 'User'}
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                {user?.email || 'user@example.com'}
              </div>
            </div>
          </div>
        </MenuDropdownItem>

        <MenuDropdownDivider />

        <MenuDropdownItem className="hover:bg-gray-100 dark:hover:bg-gray-800 hover:border-none focus:outline-none">
          <Button
            confirm
            onClick={() => signOut()}
            className="flex items-center w-full"
          >
            Sign Out
          </Button>
        </MenuDropdownItem>
      </MenuDropdownContent>
    </MenuDropdown>
  );
};
