import React from 'react';
import * as RadixAvatar from '@radix-ui/react-avatar';
import cs from 'classnames';

interface AvatarProps {
  name: string;
  src?: string;
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | '2XL';
  color?: 'grey' | 'blue' | 'green' | 'orange' | 'red' | 'magenta';
}

const sizeClasses: Record<string, string> = {
  XS: 'w-6 h-6 text-xs',
  S: 'w-8 h-8 text-sm',
  M: 'w-10 h-10 text-base',
  L: 'w-12 h-12 text-lg',
  XL: 'w-14 h-14 text-xl',
  '2XL': 'w-16 h-16 text-2xl',
};

const colorClasses: Record<string, string> = {
  grey: 'bg-gray-500',
  blue: 'bg-blue-500',
  green: 'bg-green-500',
  orange: 'bg-orange-500',
  red: 'bg-red-500',
  magenta: 'bg-pink-500',
};

export const Avatar: React.FC<AvatarProps> = ({
  name,
  src,
  size = 'S',  // Default size
  color = 'grey',  // Default color
}) => {
  const avatarSize = size ?? 'S';
  const avatarColor = color ?? 'grey';

  return (
    <RadixAvatar.Root
      className={cs(
        'rounded-full flex items-center justify-center text-white',
        sizeClasses[avatarSize],
        colorClasses[avatarColor]
      )}
    >
      {src ? (
        <RadixAvatar.Image
          className="w-full h-full object-cover rounded-full"
          src={src}
          alt={name}
        />
      ) : (
        <RadixAvatar.Fallback className="uppercase font-medium">
          {name.charAt(0)}
        </RadixAvatar.Fallback>
      )}
    </RadixAvatar.Root>
  );
};
