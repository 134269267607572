import { ComponentProps, FC, ReactNode, forwardRef } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';

type CommonProps = {
  children: ReactNode;
};

type TypeControlled = {
  open: boolean;
  onClose: () => void;
  defaultOpen?: never;
};
type TypeUncontrolled = {
  open?: never;
  onClose?: never;
  defaultOpen?: boolean;
};

export type MenuDropdownProps = CommonProps &
  (TypeControlled | TypeUncontrolled);

export const MenuDropdown: FC<MenuDropdownProps> = ({
  open,
  onClose,
  defaultOpen,
  children,
}) => {
  return (
    <DropdownMenu.Root
      open={open}
      onOpenChange={onClose}
      defaultOpen={defaultOpen}
    >
      {children}
    </DropdownMenu.Root>
  );
};

export const MenuDropdownTrigger: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>;
};

export const MenuDropdownContent = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof DropdownMenu.Content>
>(function MenuDropdownContent({ children, className, ...props }, ref) {
  return (
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        {...props}
        ref={ref}
        className={classNames(
          'p-2 bg-white rounded-md border border-gray-200 shadow-md z-50',
          'transition-opacity duration-200 ease-in-out',
          className
        )}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  );
});

export const MenuDropdownItem = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof DropdownMenu.Item>
>(function MenuDropdownItem({ children, className, ...props }, ref) {
  return (
    <DropdownMenu.Item
      {...props}
      ref={ref}
      className={classNames(
        'px-3 py-2 hover:bg-gray-50 focus:bg-gray-50 cursor-pointer',
        className
      )}
    >
      {children}
    </DropdownMenu.Item>
  );
});

export const MenuDropdownDivider: FC = () => {
  return <DropdownMenu.Separator className="h-px bg-gray-200 my-2" />;
};
