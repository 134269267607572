export const ROUTES = {
  ADMIN: {
    DASHBOARD: { name: "Dashboard", path: "/admin/dashboard" },
    DATA_CONNECTIONS: {
      name: "Data Connections",
      path: "/admin/data-connections",
    },
    EXISTING_CONNECTIONS: {
      name: "Existing Connections",
      path: "/admin/existing-connections",
    },
    NEW_CONNECTION: { name: "New Connection", path: "/admin/new-connection" },
    DATA_MANAGEMENT: {
      name: "Data Management",
      path: "/admin/data-management",
    },
    LIVE_SYNCS: { name: "Live Syncs", path: "/admin/live-syncs" },
    NEW_SYNC: { name: "New Sync", path: "/admin/new-sync" },
    USER_MANAGEMENT: {
      name: "User Management",
      path: "/admin/user-management",
    },
    REPORTS_ANALYTICS: {
      name: "Reports & Analytics",
      path: "/admin/reports-analytics",
    },
    CLIENT_PORTAL_SETTINGS: {
      name: "Client Portal",
      path: "/admin/client-portal-settings",
    },
    SETTINGS: { name: "Settings", path: "/admin/settings" },
    AUDIT_LOGS: { name: "Audit Logs", path: "/admin/audit-logs" },
    SEARCH: { name: "Search", path: "/admin/search" },
    HELP: { name: "Help", path: "/admin/help" },
    PROFILE: { name: "Profile", path: "/admin/profile" },
  },
  LOGIN: {
    name: "Login",
    path: "/login",
  },
};
