import React, { useEffect } from 'react';
import { useAuth } from '@workos-inc/authkit-react';
import { useNavigate } from 'react-router-dom';

export const Login: React.FC = () => {
  const { user, signIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/admin/dashboard', { replace: true });
    }
  }, [user, navigate]);

  const handleLogin = () => {
    signIn();
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white">
      <button
        onClick={handleLogin}
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Log in with WorkOS
      </button>
    </div>
  );
};
