import React from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/20/solid';
import {
  CalendarIcon,
  ChartPieIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  Cog6ToothIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  Bars3BottomLeftIcon,
} from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '@workos-inc/authkit-react';
import { ROUTES } from '../constants/paths';
import { Avatar } from './Avatar';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { closeSidebar } from '../store/slices/sidebarSlice';

interface SidebarItem {
  name: string;
  href?: string;
  icon: React.ElementType;
  children?: { name: string; href: string }[];
}

const navigation: SidebarItem[] = [
  { name: ROUTES.ADMIN.DASHBOARD.name, href: ROUTES.ADMIN.DASHBOARD.path, icon: HomeIcon },
  {
    name: ROUTES.ADMIN.DATA_CONNECTIONS.name,
    icon: FolderIcon,
    children: [
      { name: 'Existing Connections', href: ROUTES.ADMIN.EXISTING_CONNECTIONS.path },
      { name: 'New Connection', href: ROUTES.ADMIN.NEW_CONNECTION.path },
    ],
  },
  {
    name: ROUTES.ADMIN.DATA_MANAGEMENT.name,
    icon: FolderIcon,
    children: [
      { name: 'Live Syncs', href: ROUTES.ADMIN.LIVE_SYNCS.path },
      { name: 'New Sync', href: ROUTES.ADMIN.NEW_SYNC.path },
    ],
  },
  { name: ROUTES.ADMIN.USER_MANAGEMENT.name, href: ROUTES.ADMIN.USER_MANAGEMENT.path, icon: UsersIcon },
  { name: ROUTES.ADMIN.REPORTS_ANALYTICS.name, href: ROUTES.ADMIN.REPORTS_ANALYTICS.path, icon: ChartPieIcon },
  { name: ROUTES.ADMIN.CLIENT_PORTAL_SETTINGS.name, href: ROUTES.ADMIN.CLIENT_PORTAL_SETTINGS.path, icon: CalendarIcon },
];

const bottomNavigation: SidebarItem[] = [
  { name: ROUTES.ADMIN.SETTINGS.name, href: ROUTES.ADMIN.SETTINGS.path, icon: Cog6ToothIcon },
  { name: ROUTES.ADMIN.AUDIT_LOGS.name, href: ROUTES.ADMIN.AUDIT_LOGS.path, icon: Bars3BottomLeftIcon },
  { name: ROUTES.ADMIN.SEARCH.name, href: ROUTES.ADMIN.SEARCH.path, icon: MagnifyingGlassIcon },
  { name: ROUTES.ADMIN.HELP.name, href: ROUTES.ADMIN.HELP.path, icon: QuestionMarkCircleIcon },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const Sidebar: React.FC = () => {
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const isSidebarOpen = useAppSelector((state) => state.sidebar.isSidebarOpen);

  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
  const profilePicture = user?.profilePictureUrl || undefined;

  return (
    <>
      {/* Backdrop Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-40 bg-black bg-opacity-50 lg:hidden"
          onClick={() => dispatch(closeSidebar())}
        />
      )}

      {/* Sidebar Component */}
      <div
        className={classNames(
          "fixed inset-y-0 left-0 z-50 transform transition-transform lg:translate-x-0 lg:static lg:inset-auto lg:z-0",
          isSidebarOpen ? "translate-x-0" : "-translate-x-full",
          "flex shadow-lg dark:shadow-xl flex-col gap-y-5 overflow-y-auto dark:bg-gray-900 bg-white p-6 w-64 min-h-screen"
        )}
        style={{ minWidth: '16rem' }}
      >
        <div className="flex h-16 shrink-0 items-center justify-between">
          <img
            alt="Your Company"
            src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
            className="h-8 w-auto"
          />
          <button onClick={() => dispatch(closeSidebar())} className="lg:hidden">
            <XMarkIcon className="h-6 w-6 text-gray-800 dark:text-gray-200" />
          </button>
        </div>

        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    {!item.children ? (
                      <Link
                        to={item.href!}
                        onClick={() => dispatch(closeSidebar())}
                        className={classNames(
                          location.pathname === item.href
                            ? 'bg-blue-500 text-white'
                            : 'text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800',
                          'group flex gap-x-3 rounded-md px-4 py-2 text-sm font-semibold leading-6 transition w-full'
                        )}
                      >
                        <item.icon aria-hidden="true" className="h-6 w-6 shrink-0 text-gray-400 dark:text-gray-300" />
                        {item.name}
                      </Link>
                    ) : (
                      <Disclosure as="div" key={item.name}>
                        <DisclosureButton
                          className={classNames(
                            location.pathname.startsWith(item.href!)
                              ? 'bg-blue-500 text-white'
                              : 'text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800',
                            'group flex w-full items-center gap-x-3 rounded-md px-4 py-2 text-left text-sm font-semibold leading-6 transition w-full'
                          )}
                        >
                          <item.icon aria-hidden="true" className="h-6 w-6 shrink-0 text-gray-400 dark:text-gray-300" />
                          {item.name}
                          <ChevronRightIcon
                            aria-hidden="true"
                            className="ml-auto h-5 w-5 shrink-0 text-gray-400 dark:text-gray-300 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                          />
                        </DisclosureButton>
                        <DisclosurePanel as="ul" className="mt-1 px-2">
                          {item.children.map((subItem) => (
                            <li key={subItem.name}>
                              <Link
                                to={subItem.href}
                                onClick={() => dispatch(closeSidebar())}
                                className={classNames(
                                  location.pathname === subItem.href
                                    ? 'bg-blue-500 text-white'
                                    : 'hover:bg-gray-50 dark:hover:bg-gray-800',
                                  'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 dark:text-gray-100'
                                )}
                              >
                                {subItem.name}
                              </Link>
                            </li>
                          ))}
                        </DisclosurePanel>
                      </Disclosure>
                    )}
                  </li>
                ))}
              </ul>
            </li>

            {/* Bottom Sidebar Items */}
            <li>
              <ul className="-mx-2 space-y-1">
                {bottomNavigation.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href!}
                      onClick={() => dispatch(closeSidebar())}
                      className={classNames(
                        location.pathname === item.href
                          ? 'bg-blue-500 text-white'
                          : 'text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800',
                        'group flex gap-x-3 rounded-md px-4 py-2 text-sm font-semibold leading-6 transition w-full dark:text-gray-100'
                      )}
                    >
                      <item.icon aria-hidden="true" className="h-6 w-6 shrink-0 text-gray-400 dark:text-gray-300" />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            {/* Profile Section */}
            <li className="-mx-6 mt-auto">
              <Link
                to={ROUTES.ADMIN.PROFILE.path}
                onClick={() => dispatch(closeSidebar())}
                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800"
              >
                <Avatar name={fullName} src={profilePicture} size="S" />
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true">{fullName || 'Unknown User'}</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
