import React from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '../i18n/constants';
import { Button } from './Button';

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <div className="flex space-x-4">
      {SUPPORTED_LANGUAGES.map((lang) => (
        <Button
          key={lang.code}
          onClick={() => changeLanguage(lang.code)}
          confirm={i18n.language === lang.code}
          className="px-4 py-2"
        >
          {lang.name}
        </Button>
      ))}
    </div>
  );
};
