import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '../components/LanguageSwitcher';

export const Settings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">{t('admin.settings.title')}</h1>
      <p className="mb-6">{t('admin.settings.body')}</p>

      {/* Language Switcher Section */}
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2">{t('admin.settings.language')}</h2>
        <LanguageSwitcher />
      </div>
    </div>
  );
};
