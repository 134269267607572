import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  SortingState,
} from '@tanstack/react-table';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronUpDownIcon,
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';

type User = {
  id: number;
  name: string;
  email: string;
  role: string;
  plan: string;
  billing: string;
  status: string;
};

const columnHelper = createColumnHelper<User>();

interface UsersTableProps {
  data: User[];
}

const UsersTable: React.FC<UsersTableProps> = ({ data }) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'User',
        cell: (info) => (
          <div className="flex items-center">
            <span className="bg-gray-200 text-gray-500 rounded-full w-8 h-8 flex items-center justify-center mr-2">
              {info.row.original.name[0].toUpperCase()}
            </span>
            <div>
              <div className="font-semibold">{info.getValue()}</div>
              <div className="text-sm text-gray-500">{info.row.original.email}</div>
            </div>
          </div>
        ),
      }),
      columnHelper.accessor('role', {
        header: 'Role',
        cell: (info) => (
          <div className="flex items-center gap-2">
            <span className="bg-gray-100 px-2 py-1 rounded-lg">{info.getValue()}</span>
          </div>
        ),
      }),
      columnHelper.accessor('plan', {
        header: 'Plan',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('billing', {
        header: 'Billing',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: ({ getValue }) => {
          const value = getValue();
          return (
            <span
              className={`px-2 py-1 rounded text-white ${value === 'Active'
                ? 'bg-green-500'
                : value === 'Inactive'
                  ? 'bg-gray-500'
                  : 'bg-yellow-500'
                }`}
            >
              {value}
            </span>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting,
      pagination,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const pages = Array.from({ length: table.getPageCount() }, (_, i) => i);

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <div className="flex justify-between mb-4">
        <input
          type="text"
          value={globalFilter ?? ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search users..."
          className="border p-2 rounded"
        />
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="border p-2 rounded"
        >
          {[10, 20, 30].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full table-fixed border-collapse border border-gray-300 rounded-md">
          <thead className="bg-gray-100">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={`group border-b border-gray-300 px-4 py-2 text-left cursor-pointer ${index === 0 ? 'w-[300px]' : 'w-[150px]'}`}
                    onClick={header.column.getCanSort() ? () => header.column.toggleSorting() : undefined}
                  >
                    <div className="flex items-center">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <span className="ml-1">
                        {header.column.getIsSorted() === 'asc' ? (
                          <ChevronUpIcon className="w-4 h-4 inline" />
                        ) : header.column.getIsSorted() === 'desc' ? (
                          <ChevronDownIcon className="w-4 h-4 inline" />
                        ) : (
                          <ChevronUpDownIcon className="w-4 h-4 inline opacity-0 group-hover:opacity-100 transition-opacity duration-150 ease-in-out" />
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="hover:bg-gray-50">
                {row.getVisibleCells().map((cell, index) => (
                  <td key={cell.id} className={`border-b border-gray-300 px-4 py-2 ${index === 0 ? 'w-[300px]' : 'w-[150px]'}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} className="px-2 py-1 border rounded">
            <ChevronDoubleLeftIcon className="w-4 h-4" />
          </button>
          <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} className="px-2 py-1 border rounded">
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
        </div>

        <div className="flex gap-2">
          {pages.map((page) => (
            <button key={page} onClick={() => table.setPageIndex(page)} className={`px-3 py-1 rounded ${table.getState().pagination.pageIndex === page ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-800'}`}>
              {page + 1}
            </button>
          ))}
        </div>

        <div className="flex items-center gap-2">
          <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} className="px-2 py-1 border rounded">
            <ChevronRightIcon className="w-4 h-4" />
          </button>
          <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} className="px-2 py-1 border rounded">
            <ChevronDoubleRightIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
