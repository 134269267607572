import React from 'react';

interface CardProps {
  title: string;
  subtitle?: string;
  number?: string;
  percentage?: string;
  percentageColor?: 'text-green-500' | 'text-red-500';
  children?: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  number,
  percentage,
  percentageColor = 'text-green-500',
  children,
}) => {
  return (
    <div className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow-md text-gray-900 dark:text-gray-100">
      <div className="mb-2">
        <h3 className="text-gray-500 dark:text-gray-400 text-sm">{subtitle}</h3>
        <h2 className="text-xl font-semibold">{title}</h2>
      </div>
      {number && (
        <div className="flex items-center justify-between">
          <div className="text-2xl font-bold">{number}</div>
          {percentage && (
            <div className={`text-sm ${percentageColor}`}>
              {percentage}
            </div>
          )}
        </div>
      )}
      {children && <div className="mt-4">{children}</div>}
    </div>
  );
};
