import React, { useState, useEffect } from 'react';
import { UserSettingsButton } from './UserSettingsButton';
import { SunIcon, MoonIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { useAppDispatch } from '../store/hooks';
import { toggleSidebar } from '../store/slices/sidebarSlice';

export const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? (savedTheme as 'light' | 'dark') : 'light';
  });

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    document.documentElement.classList.toggle('dark', theme === 'dark');
  }, [theme]);

  return (
    <header className="bg-white dark:bg-gray-900 shadow-lg dark:shadow-xl top-4 mx-6 mt-4 p-4 flex justify-end items-center z-10 rounded-lg">
      <button
        onClick={() => dispatch(toggleSidebar())}
        className="lg:hidden text-gray-800 dark:text-gray-200 mr-auto p-2 rounded-full"
      >
        <Bars3Icon className="h-6 w-6" />
      </button>

      <button
        onClick={toggleTheme}
        className="text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 p-2 rounded-full transition-all duration-300"
      >
        {theme === 'light' ? <MoonIcon className="h-6 w-6" /> : <SunIcon className="h-6 w-6" />}
      </button>
      <UserSettingsButton />
    </header>
  );
};
