import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChartComponent } from '../components/Chart';
import { Card } from '../components/Card';

export const AdminDashboard: React.FC = () => {
  const { t } = useTranslation();

  // Mock data
  const chartData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    data: [120, 150, 180, 220, 200, 170, 210],
  };

  return (
    <div className="p-4 space-y-4 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 min-h-screen">
      <h1 className="text-2xl font-semibold mb-4">{t('admin.dashboard.title')}</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card title="Order" subtitle="Last week" number="124k" percentage="+12.6%" />
        <Card title="Sales" subtitle="Last Year" number="175k" percentage="-16.2%" />
        <Card title="Total Profit" subtitle="Last week" number="1.28k" percentage="-12.2%" />
        <Card title="Total Sales" subtitle="Last week" number="24.67k" percentage="+24.5%" />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
        <Card title="Revenue Growth" subtitle="Weekly Report">
          <div className="min-h-[350px] flex justify-center items-center">
            <ChartComponent
              title="Weekly Revenue Growth"
              labels={chartData.labels}
              datasetLabel="Revenue"
              data={chartData.data}
              size="M"
            />
          </div>
        </Card>

        <Card title="Sales" subtitle="Last 6 Months">
          <div className="min-h-[350px] flex justify-center items-center">
            <ChartComponent
              title="Sales Overview"
              labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']}
              datasetLabel="Sales"
              data={[300, 500, 400, 700, 650, 850]}
              size="M"
            />
          </div>
        </Card>
      </div>
    </div>
  );
};
